import React, { useState } from 'react';
import './App.css';

function App() {
	const [isNavVisible, setIsNavVisible] = useState(false);

	const toggleNav = () => {
		setIsNavVisible(!isNavVisible);
	};

	return (
		<div className="App">
			<header className="App-header">
				<h1><a href="/" style={{ color: 'inherit', textDecoration: 'none' }}>Sagar Yadav</a></h1>
				<button onClick={toggleNav} className="hamburger-btn">
					{/* Hamburger Icon */}
					<div className="hamburger-icon">
						<span></span>
						<span></span>
						<span></span>
					</div>
				</button>
				<nav className={`App-nav ${isNavVisible ? 'visible' : ''}`}>
					<ul>
						<li><a href="mailto:sagarxyadav@gmail.com" target="_blank" rel="noopener noreferrer">Email</a></li>
						<li><a href="https://twitter.com/saagryadv" target="_blank" rel="noopener noreferrer">Twitter</a></li>
						<li><a href="https://www.linkedin.com/in/sagr/" target="_blank" rel="noopener noreferrer">LinkedIn</a></li>
						<li><a href="https://github.com/svgvr8" target="_blank" rel="noopener noreferrer">GitHub</a></li>
					</ul>
				</nav>
			</header>
			<main className="App-main">
				<section className="App-content">
					<h2>Past: PONStech, Altbase, Web3.</h2>
					<p>I began my career at Summer Time Tech, delving into software development and team management. My journey then led me to PONStech, where I developed my skills in image processing and machine learning. At Altbase, in my role as a Blockchain Engineer, I focused on asset tokenization and transaction security on the Ethereum blockchain. Later, at a web3 startup, I honed my expertise in user interface programming and server-side integration for blockchain systems, particularly using Solidity and Ethereum virtual machines. Alongside this professional path, I completed a Master's in Cybersecurity from Florida International University, Miami, complementing my undergraduate degree in Biomedical Engineering.</p>
					<div className="App-footer">
						<span>BLOCKCHAIN</span>
						<span>FULLSTACK</span>
						<span>CYBERSECURITY</span>
					</div>
				</section>
			</main>
			<main className="App-main">
				<section className="App-content">
					<h2><a href="https://chain.sagaryadav.xyz">BrowserChain: A Browser Blockchain.</a></h2>
					<p>Live at chain.sagaryadav.xyz. A blockchain (not to be confused with blockchain "network") in the browser, blocks are added using Blake2 hashing, chained and verified through patricia tree, and signed through ethereum keys or metamask. This is a centralized blockchain with no consensus, and can deliver speed of 200,000 blocks per second on a personal computer. This can be used for data security. No tokens yet.</p>
					<div className="App-footer">
						<span>BLAKE2 HASHING</span>
						<span>METAMASK</span>
						<span>PATRICIA TREE</span>
					</div>
				</section>
			</main>
			<main className="App-main">
				<section className="App-content">
					<h2><a href="https://bytecode.browserchain.xyz">Bytecode Reversing: Smart Contract Security.</a></h2>
					<p>Live at bytecode.browserchain.xyz. A security tool that is designed to analyze unverified smart contracts on the EVM. It reverse-engineers bytecode into opcodes and replaces them with equivalent Solidity code for readability. If you're hacked by an unverified contract, you can take its address, plug it into the tool, and the tool will do the rest of the work to explain how the incident happened. Web3 Security incurs a cost of $10 billion per year in cybersecurity incidents.</p>					<div className="App-footer">
						<span>WEB3 SECURITY</span>
						<span>BYTECODE</span>

						<span>CONTRACT HACKING</span>
					</div>
				</section>
			</main>
			<main className="App-main">
				<section className="App-content">
					<h2><a href="https://network.sagaryadav.xyz">BrowserSOC: AI-driven Network Security.</a></h2>
					<p>Live at chain.sagaryadav.xyz. A network packet analyzer, with use of AI for automated malicious activity detection. It uses scapy to capture packets, react.js to display it in browser, a binary classification machine learning model to detect malicious packets, and the same react.js interface to display the result. Currently, it only runs on my server for security and ethical reasons. Feel free to try it on my github.</p>
					<div className="App-footer">
						<span>MACHINE LEARNING</span>
						<span>TCP DUMP</span>

						<span>NETWORK SECURITY</span>
					</div>
				</section>
			</main>
		</div>
	);
}

export default App;
